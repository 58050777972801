#root {
  height: 100vh !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.ui.menu .item::before {
  display: none !important;
}

.ui.menu:after {
  display: none !important;
}

.divider {
  border-right: 1px solid #000000;
  border-bottom: 1px solid #000000
}

.ui.menu.sidebar {
  height: max-content !important;
}

.ui.ordered.list .list>.item::before,
.ui.ordered.list>.item::before,
ol.ui.list li::before {
  color: #000000 !important;
  content: counters(ordered, ".") "." !important;
  font-weight: bold !important;
  margin-left: -3.25rem !important;
  opacity: 1 !important;
}

.publicationsMenu.ui.tabular.fluid.menu {
  width: 100% !important;
}

.filterOptions {
  min-width: 5rem;
}

.filterOptions.active {
  background-color: #c00404 !important;
  color: #ffffff !important;
}

.filterOptions:not(.filterOptions.active):hover {
  background-color: #c0040480 !important;
  color: #ffffff;
}

.pageHeaderBackground {
  display: flex;
  height: 10rem;
  position: relative;
  width: 100%;
}

.pageHeader {
  background: rgba(0, 0, 0, 0.4);
  border-radius: 10px;
  box-shadow: 0 0 50px 0 rgba(0, 0, 0, 1);
  color: #ffffff !important;
  left: 50%;
  margin: 0 !important;
  padding: 0 1rem !important;
  position: absolute;
  top: 50%;
  translate: -50% -50%;
  white-space: nowrap;
}

.pageHeaderImg {
  object-fit: cover;
  width: 100%;
}

.pageSegment {
  margin: 5rem 2rem;
}

.ui.modal>.content>.description {
  display: flex !important;
  flex-direction: column !important;
  gap: 2rem !important;
  width: 100% !important;
}

.ui.image img {
  aspect-ratio: 1 / 1;
  object-fit: cover;
  width: 100% !important;
}

.ui.modal>.galleryModal.image.content {
  justify-content: center !important;
}

.pageSegment.admin {
  margin: 2rem !important;
}

.homeSegement.admin {
  min-height: 30rem !important;
}

.uploadImage {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-bottom: 1rem;
}

.uploadImageLabel {
  margin: 0 !important;
}

.imagePreview {
  width: 25rem;
  object-fit: contain;
}

.buttonsGroup {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
}

.richTextEditor {
  width: 100% !important;
}

.rdw-editor-toolbar {
  margin: 0 !important;
}

.editor.rdw-editor-main {
  border: 1px solid #F1F1F1;
  border-top: 0 !important;
  min-height: 10rem;
  padding: 1rem !important;
}

.public-DraftStyleDefault-block.public-DraftStyleDefault-ltr {
  margin: 0 !important;
  padding: 0 !important;
}

.public-DraftStyleDefault-orderedListItem.public-DraftStyleDefault-listLTR::before {
  left: -20px !important;
  top: 50% !important;
  translate: -50% -50% !important;
}

.rdw-link-modal-target-option {
  display: none !important;
}

.rdw-link-modal {
  display: flex !important;
  gap: 0.5rem !important;
}

.ui.form input:not([type]) {
  padding: 0.25rem !important;
}

.latestNewsTitle {
  color: #000000;
  cursor: pointer;
  font-weight: bold;
  margin-bottom: 0.5rem;
}

.ui.menu .ui.dropdown .menu>a.item,
.ui.menu .ui.dropdown .menu>a.active.item {
  border-bottom: 1px #00000033 solid !important;
  white-space: break-spaces;
}

.divider.text:not(.adminInput .divider.text) {
  padding: 1rem !important;
}

.divider.text {
  border: none !important;
  color: #000000 !important;
}

.ui.inverted.menu {
  color: #000000 !important;
}

.formGroup {
  display: flex !important;
  gap: 1rem !important;
}

.adminInput {
  width: max-content !important;
}

.adminInput .ui.fluid.input,
.adminInput .ui.fluid.selection.dropdown {
  min-width: 25rem !important;
}

.editContainer {
  margin: 2rem !important;
}

.selectedImagePreview,
.saveBtn {
  margin: 1rem 0 !important;
}

.imageAndDescription {
  display: flex;
  align-items: flex-start;
  gap: 2rem;
}

.adminInputContainer {
  margin: 1rem 0 !important;
}

.adminInputContainer>.equal.width.fields {
  align-items: flex-end !important;
}

.buttonsGroup .divider.text {
  color: #ffffff !important;
  font-weight: 700 !important;
}

.btnWidth {
  min-width: 15rem !important;
  text-align: left !important;
  margin: 0 !important;
}

.ui.dropdown .visible.menu.transition {
  border: 1px solid #96c8da !important;
  border-top-width: 1px !important;
  border-bottom-width: 1px !important;
}

.adminFilterDetails {
  min-width: 10rem;
  max-width: max-content;
  text-align: left;
}

.ui.form .disabled.field,
.ui.form .disabled.fields .field,
.ui.form .field :disabled,
.ui.form .field.disabled>label,
.ui.form .fields.disabled>label {
  opacity: 1 !important;
}

.field.adminInput.disabled .dropdown.icon {
  display: none !important;
}

.addFilterBtn {
  display: flex !important;
  align-items: flex-end !important;
}

.closeIcon {
  cursor: pointer;
}

.adminInput.orderDropdown {
  width: 10rem !important;
}

.selectedOrder {
  display: flex;
  justify-content: center;
  gap: 1rem;
}

.datePicker {
  width: max-content !important;
}

.ui.form .fields .datePicker .field {
  width: 100% !important;
}

.ui.buttons .button {
  border-radius: 0.28571429rem !important;
}

.adminInput.latestNewsTitle {
  cursor: default !important;
}

.clearBtn,
.addBtn,
.deleteBtn {
  margin: 1rem 0 !important;
}

.rdw-link-decorator-icon {
  display: none !important;
}

.memberDeleteDetail {
  display: flex;
  gap: 1rem;
}

.margin-auto {
  margin: auto !important;
}

.cursor-pointer {
  cursor: pointer;
}

.mb-2 {
  margin-bottom: 2rem;
}

.reorder {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.requestsFilter {
  display: flex;
  align-items: center;
  gap: 2rem;
}

.adminInputContainer.requests {
  margin: 0 !important;
  width: 15rem !important;
}

.requestTable {
  margin-top: 1rem !important;
  text-align: center;
}

.m-0 {
  margin: 0 !important;
}

.redFont {
  color: red !important;
}

.m-75 {
  margin: 0 0.5rem;
}

.adminInputContainer.adminForm>.equal.width.fields {
  align-items: flex-start !important;
}

.text-align-center {
  text-align: center;
}

.multipleImages {
  gap: 1rem;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
  margin: 1rem 0 0;
}

.singleImage {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 25rem;
}

.mulipleImageUpload {
  margin: 1rem 0;
}

.imageCloseIcon {
  cursor: pointer;
  box-shadow: 0 0px 10px 5px white;
  position: absolute;
  top: 5px;
  right: 5px;
  width: max-content !important;
  border-radius: 50%;
  background: white;
}

.position-relative {
  position: relative;
}

.loginPage {
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background: linear-gradient(to right, #141e30, #243b55);
}

.loginPageConatiner {
  width: 360px;
  margin-top: 50px;
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
  overflow: hidden;
}

.loginHeader {
  background-color: #007bff;
  color: #ffffff;
  padding: 20px;
  text-align: center;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.loginHeader h2 {
  margin: 0;
  font-size: 24px;
}

.loginContainer {
  padding: 20px;
}

.loginInputs {
  margin-bottom: 15px;
  width: 100%;
}

.loginInputs label {
  display: block;
  font-size: 14px;
  margin-bottom: 5px;
  color: #666;
}

.loginInputs input {
  width: 100%;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 5px;
  transition: border-color 0.3s ease;
}

.loginInputs input[type="submit"] {
  background-color: #007bff;
  color: #ffffff;
  border: none;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s ease;
  width: 100%;
}

.loginInputs .ui.input {
  width: 100%;
}

.filePreview {
  margin: 1rem 0 0;
}

.fileCloseIcon {
  cursor: pointer;
  box-shadow: 0 0px 10px 5px white;
  margin: 0 1rem !important;
  width: max-content !important;
  border-radius: 50%;
  background: white;
}

.mt-1 {
  margin-top: 1rem !important;
}

.ui.message {
  width: max-content !important;
}

.deleteBtn {
  margin-top: 1rem !important;
}

.ml-1 {
  margin-left: 1rem !important;
}

.deleteImagePreview {
  width: 15rem !important;
}

.relatedPreview {
  display: flex;
  gap: 2rem;
  margin-top: 1rem !important;
}

.singleImagePreview {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.width-max {
  width: max-content !important;
}

.appFooter {
  background-color: #000000;
  color: #ffffff;
  display: flex;
  justify-content: center;
  min-height: 175px;
  width: 100%;
}

.appFooterContaint {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin: 2rem;
  text-align: center;
}

.color-white {
  color: #ffffff !important;
}

.poweredby {
  display: flex;
  white-space: nowrap;
  gap: 0.25rem;
  width: 100%;
  justify-content: center;
  align-items: center;
}

.crusoftLogo {
  width: 7rem;
  cursor: pointer;
}

.position-fixed{
  position: fixed !important;
}